<template>
  <div class="payment-methods">
    <h3 class="mx-5 mx-sm-0">{{ $t("payment.paymentTypeList.title") }}</h3>
    <v-alert v-if="isPaymentDisabled" type="error" dense>
      {{ $t("payment.paymentTypeList.errorDisabled") }}
    </v-alert>
    <v-row v-else justify="center" no-gutters>
      <v-expansion-panels
        flat
        accordion
        v-model="selectedPayment"
        :disabled="isPaymentDisabled"
      >
        <v-expansion-panel
          v-for="(paymentType, index) in paymentTypeList"
          v-bind:key="paymentType.paymentTypeId"
        >
          <v-expansion-panel-header
            hide-actions
            v-on:click.prevent="setPaymentInfo(paymentType.name)"
          >
            <!-- radio button -->
            <input
              type="radio"
              :id="'test' + paymentType.paymentTypeId"
              name="radio-group"
              :checked="selectedPayment === index ? true : false"
            />

            <!-- contenitore generale degli elementi della label -->
            <label
              :for="'test' + paymentType.paymentTypeId"
              class="d-flex flex-row"
            >
              <!-- consegna -->
              <img
                :src="`/paymentmethod/${paymentType.paymentTypeId}.svg`"
                class="mx-3"
                contain
                height="37"
                max-width="60"
              />
              <!-- nome del tipo di pagamento -->
              <div class="d-flex flex-column" @click="clicked">
                <span class="text-uppercase">
                  {{ paymentType.name }}
                </span>
                <!-- descrizione del tipo di pagamento -->
                <span class="descr" v-html="paymentType.descr"></span>
              </div>
            </label>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div :class="{ 'payment-disabled': isPaymentDisabled }">
              <GatewayWriter
                :payment-type="paymentType"
                :order-id="orderId"
                :component-name="paymentType.gatewayClient"
                :cartInfos="cartInfos"
                :mode="mode"
                :paymentDisabled="isPaymentDisabled"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
import GatewayWriter from "./gateways/GatewayWriter.vue";
import PaymentTypeService from "~/service/paymentTypeService";
import clickHandler from "~/mixins/clickHandler";
import AnalyticsService from "~/service/analyticsService";
import { mapState } from "vuex";

export default {
  name: "PaymentTypeList",
  mixins: [clickHandler],
  props: {
    orderId: { type: Number, required: false },
    mode: { type: String, required: true },
    cartInfos: { type: Object },
    isPaymentDisabled: { type: Boolean, default: false }
  },
  components: { GatewayWriter },
  data() {
    return {
      selectedPayment: 0,
      paymentTypeList: []
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    fetchPaymentTypeList() {
      PaymentTypeService.getPaymentTypeList(this.orderId, true).then(
        paymentTypeList => {
          this.paymentTypeList = paymentTypeList;
        }
      );
    },
    setPaymentInfo(name) {
      AnalyticsService.checkoutOptions(2, "payment_method", name);
      AnalyticsService.addPaymentInfo(this.cart);
    }
  },
  created() {
    this.fetchPaymentTypeList();
  }
};
</script>
